import { Observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useNavigate } from 'react-router-dom'

import paths from 'constants/paths'
import useIframeMessageStore from 'hooks/useIframeMessageStore'

import styles from './index.module.scss'

const IFRAME_URL = process.env.REACT_APP_IFRAME_URL

const ZenlegderIFrame = () => {
  const store = useIframeMessageStore()
  const searchedMessages = useRef<{ [_: string]: boolean }>({})

  const src = `${IFRAME_URL}/auth/saml/sign_in?idp_id=https%3A%2F%2Fsaml.example.com%2Fentityid`

  store?.clearMessages()

  return (
    <>
      <Observer>
        {() => {
          const messages = store?.messages || []
          const finishedEvents = useRef<{ [_: string]: boolean }>({})
          const navigate = useNavigate()

          useEffect(() => {
            messages.forEach(({ uuid, data: { eventType } }) => {
              if (searchedMessages.current[uuid]) return
              if (!eventType.match(/^taxes\./)) return

              finishedEvents.current[eventType] = true
            })
            if (Object.keys(finishedEvents.current).length === 3) {
              confirmAlert({
                title: 'Confirm to continue',
                message: 'Are you finished with your taxes?',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
                      navigate(paths.cryptoTaxes)
                    },
                  },
                  {
                    label: 'No',
                  },
                ],
                willUnmount: () => {
                  messages.forEach(({ uuid }) => {
                    searchedMessages.current[uuid] = true
                  })
                },
              })
              messages.forEach(({ uuid }) => {
                searchedMessages.current[uuid] = true
              })
              finishedEvents.current = {}
            }
          }, [messages.length])

          return null
        }}
      </Observer>
      <iframe className={styles.contentIframe} title="ZenLedger" src={src} />
    </>
  )
}

export default ZenlegderIFrame

import React from 'react'

import { IframeMessage } from 'stores/IframeMessageStore'

const ObjectDisplay = ({ value }: { value: unknown }) => {
  if (!value) return null

  if (Array.isArray(value)) {
    return (
      <ul>
        {value.map((obj, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <li key={index}>
            <ObjectDisplay value={obj} />
          </li>
        ))}
      </ul>
    )
  }
  if (typeof value === 'object') {
    return (
      <ul>
        {Object.entries(value as { [_: string]: unknown }).map(([key, obj]) => (
          <li key={key}>
            {key}: <ObjectDisplay value={obj} />
          </li>
        ))}
      </ul>
    )
  }

  return value as JSX.Element
}

const IframeMessageLogItem = ({
  message: {
    data: { eventType, data },
  },
}: {
  message: IframeMessage
}) => (
  <div>
    <ul>
      <li>
        <div>{eventType}</div>
        <div>
          {Object.entries(data).map(([key, value]) => (
            <ObjectDisplay key={key} value={{ [key]: value }} />
          ))}
        </div>
      </li>
    </ul>
  </div>
)

export default IframeMessageLogItem

import React from 'react'

import Layout from 'components/Layout'

import styles from './index.module.scss'

const Home = () => (
  <Layout>
    <div className={styles.container}>
      <h2>ZenLedger Partner Demo App</h2>
      <p>This application was made to demo the iframe sso integration with ZenLedger.</p>
    </div>
  </Layout>
)

export default Home

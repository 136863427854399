import React from 'react'
import { useNavigate } from 'react-router-dom'

import Layout from 'components/Layout'
import ZenlegderIframe from 'components/ZenlegderIframe'
import paths from 'constants/paths'

import styles from './index.module.scss'

const CryptoTaxes = () => {
  const navigate = useNavigate()

  return (
    <Layout>
      <ZenlegderIframe />
      <div className={styles.bottomBar}>
        <button type="button" onClick={() => navigate(paths.iframeMessageLog)}>
          Done
        </button>
      </div>
    </Layout>
  )
}

export default CryptoTaxes

import { makeObservable, observable, runInAction } from 'mobx'

interface IframeMessageData {
  eventType: string
  data: { [_: string]: unknown }
}

export interface IframeMessage {
  origin: string
  data: IframeMessageData
}

class IframeMessageStore {
  messages: (IframeMessage & { uuid: string })[] = []

  constructor() {
    makeObservable(this, { messages: observable })
  }

  addMessage(message: MessageEvent<IframeMessageData>) {
    const { data, origin } = message
    const uuid = crypto.randomUUID()

    runInAction(() => {
      this.messages.push({ data, origin, uuid })
    })
  }

  clearMessages() {
    runInAction(() => {
      this.messages = []
    })
  }
}

export default IframeMessageStore

import React from 'react'
import { FaHome } from 'react-icons/fa'
import { LuMessagesSquare } from 'react-icons/lu'
import { SiBitcoinsv } from 'react-icons/si'

import paths from 'constants/paths'

import styles from './index.module.scss'
import Link from './Link'

const Sidebar = () => (
  <div className={styles.sideBar}>
    <Link to={paths.home}>
      <FaHome className={styles.linkIcon} /> Home
    </Link>
    <Link to={paths.cryptoTaxes}>
      <SiBitcoinsv className={styles.linkIcon} /> Crypto Taxes
    </Link>
    <Link to={paths.iframeMessageLog}>
      <LuMessagesSquare className={styles.linkIcon} /> iframe Message Log
    </Link>
  </div>
)

export default Sidebar

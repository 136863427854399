import React, { ReactNode } from 'react'
import { Link as ReactRouterDomLink, useLocation } from 'react-router-dom'

import styles from './Link.module.scss'

const Link = ({ to, children }: { to: string; children: ReactNode }) => {
  const location = useLocation()

  const classNames = [styles.link]
  if (to.match(new RegExp(`^${location.pathname}(/*)?$`))) classNames.push(styles.active)

  return (
    <ReactRouterDomLink to={to} className={classNames.join(' ')}>
      {children}
    </ReactRouterDomLink>
  )
}

export default Link

import { Observer } from 'mobx-react-lite'
import React from 'react'

import Layout from 'components/Layout'
import useIframeMessageStore from 'hooks/useIframeMessageStore'
import IframeMessageStore from 'stores/IframeMessageStore'

import IframeMessageLogItem from './IframeMessageLogItem'
import styles from './index.module.scss'

const IframeMessageLog = () => {
  const store = useIframeMessageStore() as IframeMessageStore

  return (
    <Layout>
      <Observer>
        {() => (
          <div className={styles.messageLogs}>
            {store.messages.map((message) => (
              <IframeMessageLogItem key={message.uuid} message={message} />
            ))}
          </div>
        )}
      </Observer>
    </Layout>
  )
}

export default IframeMessageLog
